<template>
    <page-layout header-height="100">
        <template slot="header">
            <div>header</div>
        </template>
        <template slot="footer">
            <div>footer</div>
        </template>
        <template>
            <el-table :data="tableData"
                      border
                      header-row-class-name="ws-table-header"
                      height="100%"
                      class="ws-table">
                <el-table-column type="selection"
                                 width="55">
                </el-table-column>
                <el-table-column fixed
                                 prop="date"
                                 label="日期"
                                 width="150">
                </el-table-column>
                <el-table-column prop="name"
                                 label="姓名"
                                 width="120">
                </el-table-column>
                <el-table-column prop="province"
                                 label="省份"
                                 width="120">
                </el-table-column>
                <el-table-column prop="city"
                                 label="市区"
                                 width="120">
                </el-table-column>
                <el-table-column prop="zip"
                                 label="邮编"
                                 width="120">
                </el-table-column>
                <el-table-column prop="address"
                                 label="地址">
                </el-table-column>
                <el-table-column fixed="right"
                                 label="操作"
                                 width="100">
                    <template slot-scope="scope">
                        <el-button type="text"
                                   size="small">查看</el-button>
                        <el-button type="text"
                                   size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </page-layout>
</template>

<script>
    import PageLayout from "@/components/PageLayout"
    export default {
        name: 'FunctionList',
        components: {
            PageLayout
        },
        data () {
            return {
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1518 弄',
                    zip: 200333
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1517 弄',
                    zip: 200333
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1519 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                }]
            }
        },
        methods: {
            handleClick (row) {
                console.log(row);
            }
        },
        mounted () {

        }
    }
</script>
