<template>
    <el-container class="page-template">
        <el-header ref="header"
                   class="page-head"
                   v-if="showHead"
                   :height="headerHeight+'px'">
            <slot name="header" />
        </el-header>
        <el-main class="page-body">
            <slot/>
        </el-main>
        <el-footer ref="footer"
                   class="page-foot"
                   v-if="showFoot"
                   :height="footerHeight+'px'">
            <slot name="footer" />
        </el-footer>
    </el-container>
</template>

<script>
    export default {
        name: "PageLayout",
        data () {
            return {
                showHead: true,
                showFoot: true
            }
        },
        props: {
            headerHeight: {
                type: Number,
                default: 50
            },
            footerHeight: {
                type: Number,
                default: 50
            }
        },
        mounted () {
            setTimeout(() => {
                console.log(this.$refs.header.$children)
                //this.showHead = this.$refs.header.$children.length > 0;
                //this.showFoot = this.$refs.footer.$children.length > 0;
            }, 1000);
        },
        computed: {
            // showHead () {
            //     return this.$refs.header && this.$refs.header.$children && this.$refs.header.$children.length > 0;
            // },
            // showFoot () {
            //     return this.$refs.footer && this.$refs.footer.$children && this.$refs.footer.$children.length > 0;
            // }
        }
    }
</script>

<style lang="scss" scoped>
</style>
